<!--
 * @Author: jiang
 * @Date: 2021-07-12 09:34:19
 * @Description: 流程、要点、制度
-->

<template>
  <el-container
    class="g-page-list"
    style="height: 100%; padding: 0 15px 15px; overflow: hidden;"
  >
    <el-aside
      class="g-bg g-mr-15"
      v-if="userSuperId === userId"
    >
      <el-scrollbar
        y
        view-style="padding: 10px;"
      >
        <dept-tree
          ref="tree"
          @change="onDeptChange"
        ></dept-tree>
      </el-scrollbar>
    </el-aside>
    <el-container>
      <el-header
        class="g-bg main-header"
        height="80px"
      >
        <div class="dept">{{  currentDeptName || userDepartmentName }}</div>
        <el-button
          type="primary"
          @click="onNav"
        >我的</el-button>
      </el-header>
      <el-container class="g-bg g-mt-15">
        <el-main class="main-divider">
          <section-view title="已填写工作清单">
            <el-scrollbar
              y
              view-style="padding: 0 20px"
            >
              <div
                v-if="fillList.length === 0"
                class="g-empty"
              ></div>
              <div class="work-box">
                <div
                  class="work-item"
                  v-for="item in fillList"
                  :key="item.id"
                  @click="onNav(item)"
                >
                  <el-avatar
                    :size="44"
                    :src="item.avatar"
                  ></el-avatar>
                  <div class="name">{{ item.name }}</div>
                </div>
              </div>
            </el-scrollbar>
          </section-view>
        </el-main>
        <el-main>
          <section-view title="未填写工作清单">
            <el-scrollbar
              y
              view-style="padding: 0 20px"
            >
              <div
                v-if="list.length === 0"
                class="g-empty"
              ></div>
              <div class="work-box">
                <div
                  class="work-item"
                  v-for="item in list"
                  :key="item.id"
                  @click="onNav(item)"
                >
                  <el-avatar
                    :size="44"
                    :src="item.avatar"
                  ></el-avatar>
                  <div class="name">{{ item.name }}</div>
                </div>
              </div>
            </el-scrollbar>

          </section-view>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import DeptTree from '@/components/tree/dept.vue'
import SectionView from '@/components/section'
import { getUserWorkList } from '@/api/manager'
import { mapState } from 'vuex'

export default {
  components: {
    DeptTree,
    SectionView,
  },
  data() {
    return {
      userSuperId: parseInt(process.env.VUE_APP_SUPER_USER_ID),
      fillList: [],
      list: [],
      currentDept: '',
      currentDeptName: '',
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id,
      userDepartmentId: state => state.userInfo.department.id,
      userDepartmentName: state => state.userInfo.department.name,
    }),
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      // const params = {
      //  start_date: '',
      //  end_date: '',
      // }
      const deptId = this.currentDept || this.userDepartmentId
      getUserWorkList(deptId).then(res => {
        this.list = res.not_fill_in
        this.fillList = res.fill_in
      })
    },
    onDeptChange(id) {
      this.currentDept = id
      this.$refs.tree.getName(id).then(res => {
        this.currentDeptName = res
      })
      this.fetchList()
    },
    onNav(user = {}) {
      this.$router.push({
        path: '/manager/work-user',
        query: { userId: user.id, userName: user.name, dept: this.currentDeptName },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dept {
    font-size: 20px;
    font-weight: bold;
    color: #2b2d42;
  }
}

.el-main {
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.main-divider {
  border-right: 1px solid #f1f1f1;
}

.work-box {
  display: flex;
  flex-wrap: wrap;

  .work-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: rgba($color: #000, $alpha: 0.03);
    }

    .name {
      margin-top: 10px;
      font-size: 14px;
      color: #2b2d42;
    }
  }
}
</style>
